/* =================================
------------------------------------
  Cloud 83 - Hosting Template
  Version: 1.0
 ------------------------------------
 ====================================*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
	height: 100%;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: #151414;
	font-weight: 500;
}

h1 {
	font-size: 70px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 14px;
	color: #636363;
	line-height: 2;
}

img {
	max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
	margin-bottom: 87px;
	text-align: center;
	position: relative;
}

.section-title h2 {
	font-size: 48px;
	font-weight: 600;
	padding-bottom: 18px;
}

.section-title p {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: normal;
}

.section-title:after {
	position: absolute;
	content: "";
	width: 110px;
	height: 3px;
	left: 50%;
	bottom: 0;
	margin-left: -55px;
	background:  $theme-colour;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 110px;
	padding-bottom: 110px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}

/*---------------------
  Commom elements
-----------------------*/

/* buttons */

.site-btn {
	display: inline-block;
	border: none;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	min-width: 110px;
	padding: 14px 25px;
	border-radius: 0px;
	background: $theme-colour;
	cursor: pointer;
	text-align: center;
}

.site-btn span {
	font-size: 11px;
	margin-left: 2px;
}

.site-btn.sb-c1 {
	background: $theme-colour;
}

.site-btn.sb-c2 {
	background: #7fabda;
}

.site-btn.sb-c3 {
	background: $theme-colour;
}

.site-btn:hover {
	color: #fff;
}

/* Preloder */

#preloder {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
	background: #000;
}

.loader {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -13px;
	margin-left: -13px;
	border-radius: 60px;
	animation: loader 0.8s linear infinite;
	-webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

@-webkit-keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

.elements-section {
	padding-top: 50px;
}

.el-title {
	margin-bottom: 55px;
}

.element {
	margin-bottom: 100px;
}

.element:last-child {
	margin-bottom: 0;
}

/* Accordion */

.accordion-area .panel {
	margin-bottom: 6px;
}

.accordion-area .panel-link:after {
	content: "+";
	position: absolute;
	font-size: 16px;
	right: 0;
	top: 0;
	line-height: 16px;
	width: 54px;
	height: 100%;
	text-align: center;
	background: $theme-colour;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.accordion-area .panel-header .panel-link.collapsed:after {
	content: "+";
}

.accordion-area .panel-link.active:after {
	content: "-";
}

.accordion-area .panel-link {
	text-align: left;
	position: relative;
	width: 100%;
	padding: 17px 65px 17px 20px;
	padding-left: 20px;
	font-size: 14px;
	font-weight: 500;
	background: #c51f25;
	color: #fff;
	line-height: 1;
	cursor: pointer;
	border: none;
	min-height: 51px;
}

.accordion-area .panel-body {
	padding: 25px 37px 30px;
	background: #f4f7fa;
}

.accordion-area .panel-body p {
	margin-bottom: 0;
}

/* tab */

.tab-element .nav-tabs {
	border-bottom: none;
}

.tab-element .nav-tabs .nav-link {
	border-radius: 0;
	font-size: 16px;
	color: #fff;
	font-weight: 500;
	border: none;
	padding: 13px 22px;
	margin-right: 3px;
	background: $theme-colour;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.tab-element .nav-tabs .nav-item:last-child .nav-link {
	margin-right: 0;
}

.tab-element .nav-tabs .nav-link.active {
	background: #c51f25;
}

.tab-element .tab-pane {
	padding: 25px 37px 30px;
	background: #f4f7fa;
}

.tab-element .tab-pane p {
	margin-bottom: 0;
}

/* Circle progress */

.circle-progress {
	text-align: center;
	display: block;
	position: relative;
	margin-bottom: 30px;
}

.circle-progress canvas {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.circle-progress .progress-info {
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -39px;
}

.circle-progress .progress-info h2 {
	font-size: 48px;
	font-weight: 400;
}

.circle-progress .progress-info p {
	margin-bottom: 0;
	line-height: normal;
}

.cp-item {
	text-align: center;
}

.cp-item h4 {
	font-size: 14px;
	color: #7e7e7e;
}

/*------------------
  Header section
---------------------*/

.header-section {
	background: rgba(0, 0, 0, 0.63);
	padding: 14px 0px;
	border-bottom: 2px solid $theme-colour;
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 99;
}

.site-logo {
	display: inline-block;
	float: left;
}

.nav-switch {
	display: none;
}

.header-right {
	position: relative;
}

.main-menu {
	list-style: none;
	display: inline-block;
	margin-left: 220px;
	padding-top: 35px;
}

.main-menu li {
	display: inline;
}

.nav-pills .show>.nav-link {
	background-color: $theme-colour;
}

.main-menu li a {
	display: inline-block;
	font-size: 16px;
	
	position: relative;

	/* padding: 4px 14px;
	margin-left: 15px;
	font-weight: 500;
	color: #ffffff; */
}

.main-menu li.active a {
	background: $theme-colour;
}

.header-btns {
	position: absolute;
	display: flex;
	left: 100%;
	width: 390px;
	top: 30px;
	margin-left: -181px;
}

.header-btns .site-btn {
	margin-right: 11px;
}

.header-btns .site-btn:last-child {
	margin-right: 0;
}

/*------------------
  Hero section
---------------------*/

.hero-slider .owl-dots,
.testimonials-slider .owl-dots {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 5;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.hero-slider .owl-dots .owl-dot,
.testimonials-slider .owl-dots .owl-dot {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 33px;
	min-width: 33px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 14px;
	color: #fff;
	margin: 0 2px;
	margin-bottom: 31px;
}

.hero-slider .owl-dots .owl-dot.active,
.testimonials-slider .owl-dots .owl-dot.active {
	background: $theme-colour;
}

.hs-item {
	height: 548px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 150px;
}

.hs-item h2 {
	font-size: 72px;
	color: #ffffff;
	margin-bottom: 20px;
	top: 100px;
	opacity: 0;
	position: relative;
}

.hs-item p {
	font-size: 25px;
	display: inline-block;
	/*background: #ffffff;*/
	color: #ffffff;
	padding: 0 15px;
	margin-bottom: 3px;
	line-height: 1.8;
	top: 60px;
	opacity: 0;
	position: relative;
}

.hs-item .site-btn {
	margin-top: 65px;
	min-width: 146px;
	top: 40px;
	opacity: 0;
	position: relative;
}

.owl-item.active .hs-item h2,
.owl-item.active .hs-item p,
.owl-item.active .hs-item .site-btn {
	top: 0;
	opacity: 1;
}

.owl-item.active .hs-item h2 {
	-webkit-transition: all 1.5s ease 0.6s;
	transition: all 1.5s ease 0.6s;
}

.owl-item.active .hs-item p {
	-webkit-transition: all 1.5s ease 0.8s;
	transition: all 1.5s ease 0.8s;
}

.owl-item.active .hs-item .site-btn {
	-webkit-transition: all 1.5s ease 1s;
	transition: all 1.5s ease 1s;
}

/*---------------------
  About Section
-----------------------*/

.about-text h2 {
	font-weight: 600;
	margin-bottom: 35px;
}

/*---------------------
  Features Section
-----------------------*/

.feature {
	text-align: center;
}

.feature img {
	margin-bottom: 50px;
}

.feature h4 {
	margin-bottom: 20px;
}

.feature p {
	margin-bottom: 0;
}

/*---------------------
  Services Section
-----------------------*/

.services-circle {
	position: relative;
}

.service-list {
	list-style: none;
}

.service-list li {
	margin-bottom: 85px;
	position: relative;
	z-index: 2;
}

.service-list li:last-child {
	margin-bottom: 0;
}

.service-list .sl-icon {
	width: 87px;
	height: 87px;
	background: $theme-colour;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	float: left;
}

.service-list .sl-content {
	padding-left: 129px;
}

.service-list .sl-content h4 {
	margin-bottom: 15px;
}

.service-list .sl-content p {
	margin-bottom: 0;
}

.service-list.service-list-left .sl-icon {
	float: right;
	padding-left: 0;
}

.service-list.service-list-left .sl-content {
	text-align: right;
	padding-right: 129px;
	padding-left: 0;
}

.service-list.service-list-left li:nth-child(1) {
	right: 4px;
	width: 490px;
}

.service-list.service-list-left li:nth-child(2) {
	right: 80px;
}

.service-list.service-list-left li:nth-child(3) {
	right: 0;
}

.service-list.service-list-right li:nth-child(1) {
	left: -20px;
	width: 490px;
}

.service-list.service-list-right li:nth-child(2) {
	left: 80px;
}

.service-list.service-list-right li:nth-child(3) {
	left: 0;
}

.service-main-circle {
	position: absolute;
	width: 477px;
	height: 477px;
	border: 3px solid $theme-colour;
	border-radius: 50%;
	left: 50%;
	margin-left: -238.5px;
	background: rgba(255, 255, 255, 0.21);
	text-align: center;
	padding-top: 120px;
}

/*---------------------
  About Section
-----------------------*/

.about-section .section-title {
	margin-bottom: 0;
}

.about-img img {
	position: relative;
	left: -43px;
	top: -15px;
}

.about-text {
	padding-top: 82px;
}

.about-text p {
	margin-bottom: 60px;
}

.about-list {
	list-style: none;
	padding-left: 90px;
}

.about-list li {
	position: relative;
	color: #151414;
	font-size: 14px;
	padding-left: 30px;
	margin-bottom: 23px;
}

.about-list li:after {
	position: absolute;
	content: "";
	width: 15px;
	height: 15px;
	left: 0;
	top: 3px;
	border-radius: 50%;
	background: $theme-colour;
}

/*---------------------
  Milestones Section
-----------------------*/

.milestones-section {
	background: $theme-colour;
	padding: 75px 0;
}

.milestone-icon {
	float: left;
	height: 100px;
	width: 80px;
	display: flex;
	align-items: center;
	margin-left: 40px;
}

.milestone-text {
	padding-top: 15px;
	padding-left: 130px;
}

.milestone-text h2 {
	font-size: 48px;
	font-weight: 400;
	line-height: 1.1;
}

.milestone-text p {
	margin-bottom: 0;
	line-height: normal;
}

/*---------------------
  Banner Section
-----------------------*/

.banner-section {
	padding: 85px 0;
}

.banner-card {
	background: #fff;
	max-width: 770px;
	padding: 53px 95px 28px;
	position: relative;
	left: -100px;
}

.banner-card h2 {
	font-size: 48px;
	font-weight: 600;
	margin-bottom: 20px;
	line-height: 1.1;
}

.banner-card h6 {
	font-weight: 600;
	margin-bottom: 39px;
}

/*---------------------
  Footer Section
-----------------------*/

.footer-top-section {
	background: #1f2430;
	padding-top: 127px;
	padding-bottom: 50px;
}

.footer-widget {
	margin-bottom: 35px;
}

.footer-widget p {
	color: #fff;
	opacity: .55;
}

.footer-widget .fw-title {
	color: #fff;
	font-weight: 400;
	margin-bottom: 55px;
	padding-top: 20px;
}

.footer-widget ul {
	list-style: none;
}

.footer-widget ul li {
	margin-bottom: 10px;
}

.footer-widget ul li a {
	font-size: 14px;
	color: #fff;
	opacity: 0.55;
}

.footer-widget.about-widget img {
	margin-bottom: 60px;
}

.footer-widget.about-widget p {
	padding-left: 1px;
}

.footer-widget .social-links {
	padding-top: 50px;
}

.footer-widget .social-links a {
	color: #fff;
	font-size: 14px;
	margin-right: 30px;
}

.footer-widget .social-links a:last-child {
	margin-right: 0;
}

.footer-section {
	background: #171b26;
	overflow: hidden;
	padding: 19px 0;
}

.footer-nav {
	float: right;
}

.footer-nav ul {
	list-style: none;
}

.footer-nav ul li {
	display: inline;
}

.footer-nav ul li a {
	display: inline-block;
	color: #51555d;
	margin-left: 35px;
	font-size: 14px;
}

.copyright {
	float: left;
	padding-top: 3px;
}

.copyright p {
	font-size: 12px;
	margin-bottom: 0;
	color: #51555d;
}

.copyright p a {
	color: #51555d;
}

.copyright p a:hover {
	text-decoration: underline;
}

/*------------------
  Page
---------------------*/

.page-top-section {
	height: 500px;
	text-align: center;
	padding-top: 300px;
}

.page-top-section h2 {
	font-size: 72px;
	color: #ffffff;
	font-weight: 600;
}

/*------------------
  About Page
---------------------*/

.about-intro-pic {
	position: relative;
}

.about-intro-pic .video-play {
	position: absolute;
	width: 64px;
	height: 64px;
	top: calc(50% - 32px);
	left: calc(50% - 32px);
	border-radius: 50%;
	background: $theme-colour;
	z-index: 2;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-intro-text p {
	margin-bottom: 35px;
}

.what-card .wc-text {
	background: #fff;
	text-align: center;
	padding: 36px 30px 20px;
}

.what-card .wc-text h5 {
	margin-bottom: 15px;
}

.testimonials-section {
	padding: 114px;
}

.testimonial-item {
	text-align: center;
}

.testimonial-item .ti-author-pic {
	width: 67px;
	height: 67px;
	border-radius: 50%;
	background-color: #333;
	display: inline-block;
	margin-bottom: 21px;
	margin-top: 50px;
}

.testimonials-slider.owl-carousel .owl-nav {
	position: absolute;
	top: 15px;
	width: 100%;
}

.testimonials-slider.owl-carousel .owl-nav button.owl-prev,
.testimonials-slider.owl-carousel .owl-nav button.owl-next {
	width: 58px;
	height: 58px;
	color: #fff;
	font-size: 25px;
	border-radius: 50%;
	background: #dfeae7;
	position: relative;
	transition: all 0.3s;
}

.testimonials-slider.owl-carousel .owl-nav button.owl-prev:hover,
.testimonials-slider.owl-carousel .owl-nav button.owl-next:hover {
	background: #c51f25;
}

.testimonials-slider.owl-carousel .owl-nav button.owl-prev {
	left: -180px;
}

.testimonials-slider.owl-carousel .owl-nav button.owl-next {
	float: right;
	right: -180px;
}

.testimonials-slider.owl-carousel .owl-dots {
	position: relative;
	padding-top: 72px;
}

.testimonials-slider.owl-carousel .owl-dots .owl-dot {
	color: #151414;
	margin-bottom: 0;
}

/*------------------
  Service Page
---------------------*/

.pricing-section {
	background-image: url("../img/flyer-red.jpg");
	/*background-repeat: repeat;*/
	width: 100%;
}

.pricing-card {
	text-align: center;
	border-style: solid;
}

.pricing-card .pc-header {
	background-repeat: repeat;
	background-position: center;
	/*background: $theme-colour;*/
	padding: 50px 20px 70px;
}

.pricing-card .pc-header h4 {
	font-weight: 600;
	color: #222529;
	margin-bottom: 5px;
}

.pricing-card .pc-header h5 {
	font-size: 48px;
	font-weight: 600;
	color: #222529;
}

.pricing-card .pc-header h5 span {
	font-size: 20px;
	font-weight: 400;
}

.pricing-card .pc-details {
	background: #fff;
	padding-bottom: 60px;
}

.pricing-card .pc-details .pc-icon {
	display: inline-flex;
	width: 94px;
	height: 94px;
	align-items: center;
	justify-content: center;
	/*background: $theme-colour;*/
	background-repeat: repeat;
	background-position: center;
	border-radius: 50%;
	margin-top: -47px;
	padding: 15px;
}
.gold {
	background-image: url("/img/gold-repeat.jpg");
}
.platinum {
	background-image: url("/img/platinum-repeat.jpg");
	}	
.diamond {
	background-image: url("/img/diamond-repeat.jpg");
}
.most-popular {
	position: absolute;
	width: 30%;
}
.pricing-card .pc-details ul {
	padding-top: 30px;
	padding-bottom: 50px;
	list-style: none;
}

.pricing-card .pc-details ul li {
	font-size: 14px;
	color: #7c7c7c;
	font-weight: 500;
	margin-bottom: 12px;
}

.venue-section {
	background-color: #ffffff;
	background-repeat: repeat;
	width: 100%;
}


/*------------------
  Blog Page
---------------------*/

.blog-post {
	margin-bottom: 115px;
}

.post-content {
	text-align: center;
	padding: 46px 0 0;
}

.post-content .post-date {
	display: inline-block;
	font-size: 14px;
	color: #fff;
	background: #c51f25;
	padding: 10px 22px;
	margin-bottom: 35px;
}

.post-content h4 {
	margin-bottom: 28px;
}

.post-content p {
	margin-bottom: 45px;
}

.post-content .site-btn {
	min-width: 147px;
}

.site-pagination {
	text-align: center;
	display: flex;
	justify-content: center;
}

.site-pagination a {
	display: inline-flex;
	height: 33px;
	min-width: 33px;
	align-items: center;
	justify-content: center;
	color: #151414;
	font-size: 14px;
}

.site-pagination .active {
	background: $theme-colour;
}

.contact-title {
	margin-bottom: 45px;
}

.contact-text p {
	margin-bottom: 35px;
}

.contact-info {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.contact-info .ci-icon {
	width: 48px;
	float: left;
}

.contact-info h6 {
	font-size: 15px;
	padding-left: 15px;
}

.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=tel],
.contact-form input[type=date],
.contact-form input[type=time],
.contact-form input[type=number],
.contact-form textarea {
	width: 100%;
	border: none;
	font-size: 14px;
	height: 61px;
	padding: 10px 32px;
	margin-bottom: 8px;
	border-bottom: 3px solid transparent;
	background: #dee2ef;
}

.contact-form input[type=text]:focus,
.contact-form input[type=email]:focus,
.contact-form textarea:focus {
	border-bottom: 3px solid #c51f25;
}

.contact-form textarea {
	height: 177px;
	padding-top: 23px;
	margin-bottom: 18px;
}

.contact-form .site-btn {
	min-width: 147px;
}

.contact-form ::-webkit-input-placeholder {
	color: #7c7c7c;
}

.contact-form :-ms-input-placeholder {
	color: #7c7c7c;
}

.contact-form ::-ms-input-placeholder {
	color: #7c7c7c;
}

.contact-form ::placeholder {
	color: #7c7c7c;
}

.map {
	margin-top: 115px;
	text-align: center;
}

.map iframe {
	width: 80%;
	height: 490px;
}

/* ----------------
  Responsive
---------------------*/

@media (min-width: 1200px) {
	.container {
		max-width: 1175px;
	}
}

@media (max-width: 1580px) {
	.header-section .container {
		max-width: 100%;
	}
	.header-btns {
		left: auto;
		right: 0;
		margin-left: 0;
		width: auto;
		margin-top: 0px;
	}
}

@media (max-width: 1330px) {
	.main-menu {
		margin-left: 90px;
	}
	.service-list li {
		margin-bottom: 40px;
	}
	.service-list .sl-icon {
		width: 80px;
		height: 80px;
	}
	.service-list .sl-content {
		padding-left: 95px;
	}
	.service-list.service-list-left .sl-icon {
		float: right;
		padding-left: 0;
	}
	.service-list.service-list-left .sl-content {
		text-align: right;
		padding-right: 95px;
		padding-left: 0;
	}
	.service-list.service-list-left li:nth-child(1) {
		right: 4px;
		width: 490px;
	}
	.service-list.service-list-left li:nth-child(2) {
		right: 30px;
	}
	.service-list.service-list-left li:nth-child(3) {
		right: -32px;
	}
	.service-list.service-list-right li:nth-child(1) {
		left: -40px;
		width: 490px;
	}
	.service-list.service-list-right li:nth-child(2) {
		left: 15px;
	}
	.service-list.service-list-right li:nth-child(3) {
		left: -32px;
	}
	.service-main-circle {
		width: 360px;
		height: 360px;
		margin-left: -180px;
		padding-top: 65px;
	}
	.about-img img {
		left: 0;
		top: 0;
	}
	.banner-card {
		left: 0;
	}
}

/* Medium screen : 992px. */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu {
		margin-left: 40px;
	}
	.main-menu li a {
		margin-left: 0;
		font-size: 14px;
	}
	.header-btns {
		top: 30px;
	}
	.header-btns .site-btn {
		padding: 10px 20px;
		margin-right: 5px;
	}
	.service-list li {
		margin-bottom: 30px;
		max-width: 330px;
	}
	.service-list .sl-icon {
		width: 80px;
		height: 80px;
	}
	.service-list .sl-content {
		padding-left: 95px;
	}
	.service-list .sl-content p {
		font-size: 12px;
	}
	.service-list .sl-content h4 {
		font-size: 20px;
	}
	.service-list.service-list-left .sl-icon {
		float: right;
		padding-left: 0;
	}
	.service-list.service-list-left .sl-content {
		text-align: right;
		padding-right: 95px;
		padding-left: 0;
	}
	.service-list.service-list-left li:nth-child(1) {
		right: -47px;
		width: auto;
	}
	.service-list.service-list-left li:nth-child(2) {
		right: 7px;
	}
	.service-list.service-list-left li:nth-child(3) {
		right: -50px;
	}
	.service-list.service-list-right li:nth-child(1) {
		left: -20px;
		width: auto;
	}
	.service-list.service-list-right li:nth-child(2) {
		left: 32px;
	}
	.service-list.service-list-right li:nth-child(3) {
		left: -20px;
	}
}

/* Tablet :768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-section {
		padding: 15px 0px;
	}
	.header-section .header-right {
		position: fixed;
		background: #fff;
		width: 300px;
		height: 100%;
		left: -340px;
		top: 0;
		overflow-y: auto;
		box-shadow: 40px 0px 70px rgba(167, 167, 167, 0.2);
		transition: all 0.4s;
	}
	.header-section .header-right.active {
		left: 0;
	}
	.header-section .container {
		max-width: 720px;
	}
	.nav-switch {
		display: block;
		width: 40px;
		float: right;
		height: 31px;
		padding-top: 14px;
		position: relative;
		cursor: pointer;
	}
	.nav-switch .ns-bar {
		width: 30px;
		height: 3px;
		background: #fff;
		transition: all 0.3s;
	}
	.nav-switch .ns-bar:after,
	.nav-switch .ns-bar:before {
		position: absolute;
		content: "";
		width: 100%;
		height: 3px;
		background: #fff;
		left: 0;
		bottom: 0;
		transition: all 0.3s;
	}
	.nav-switch .ns-bar:before {
		bottom: auto;
		top: 0;
	}
	.nav-switch:hover .ns-bar {
		width: 100%;
	}
	.nav-switch.active .ns-bar {
		width: 0px;
	}
	.nav-switch.active .ns-bar:after {
		transform: rotate(-43deg);
		transform-origin: left bottom;
	}
	.nav-switch.active .ns-bar:before {
		transform: rotate(43deg);
		transform-origin: left top;
	}
	.main-menu {
		margin-left: 0;
		padding-top: 0;
		display: block;
		margin-bottom: 80px;
	}
	.main-menu li {
		display: block;
		border-bottom: 1px solid #f1f1f1;
	}
	.main-menu li a {
		display: block;
		color: #000;
		margin-left: 0;
		padding: 12px 21px;
	}
	.main-menu li.active a {
		color: #fff;
	}
	.header-btns {
		position: relative;
		display: block;
		padding: 0 15px;
	}
	.header-btns .site-btn {
		margin-right: 0;
		display: block;
		margin-bottom: 5px;
	}
	.hs-item {
		/*height: auto;*/
		padding-top: 250px;
		padding: 250px 0 200px;
	}
	.hs-item h2 {
		font-size: 65px;
	}
	.service-list li {
		margin-bottom: 40px;
		max-width: auto;
	}
	.service-list.service-list-left .sl-icon {
		float: right;
		padding-left: 0;
	}
	.service-list.service-list-left .sl-content {
		text-align: right;
		padding-right: 95px;
		padding-left: 0;
	}
	.service-list.service-list-left li:nth-child(1) {
		right: -0;
		width: auto;
	}
	.service-list.service-list-left li:nth-child(2) {
		right: 0;
	}
	.service-list.service-list-left li:nth-child(3) {
		right: 0;
	}
	.service-list.service-list-right li:nth-child(1) {
		left: 0;
		width: auto;
	}
	.service-list.service-list-right li:nth-child(2) {
		left: 0;
	}
	.service-list.service-list-right li:nth-child(3) {
		left: 0;
	}
	.service-list.service-list-left .sl-icon {
		float: left;
	}
	.service-list.service-list-left .sl-content {
		text-align: left;
		padding-right: 0;
		padding-left: 0;
		padding-left: 95px;
	}
	.service-main-circle {
		display: none;
	}
	.about-img {
		text-align: center;
		padding-top: 80px;
	}
	.what-card .wc-text {
		padding: 20px 15px 1px;
	}
	.testimonials-slider.owl-carousel .owl-nav {
		position: relative;
		top: 0;
		text-align: center;
		padding-top: 50px;
	}
	.testimonials-slider.owl-carousel .owl-nav button.owl-prev,
	.testimonials-slider.owl-carousel .owl-nav button.owl-next {
		float: none;
		left: 0;
		right: 0;
		width: 50px;
		height: 50px;
		font-size: 20px;
		margin: 0 9px;
	}
	.testimonials-slider.owl-carousel .owl-dots {
		padding-top: 30px;
	}
	.pricing-card .pc-header h4 {
		font-size: 16px;
	}
	.pricing-card .pc-header h5 {
		font-size: 35px;
	}
	.add-pic,
	.contact-text,
	.milestone-icon,
	.about-intro-pic {
		margin-bottom: 40px;
	}
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
	.header-section {
		padding: 15px 0px;
	}
	.header-section .header-right {
		position: fixed;
		background: #fff;
		width: 300px;
		height: 100%;
		left: -340px;
		top: 0;
		overflow-y: auto;
		box-shadow: 40px 0px 70px rgba(167, 167, 167, 0.2);
		transition: all 0.4s;
	}
	.header-section .header-right.active {
		left: 0;
	}
	.header-section .container {
		max-width: 720px;
	}
	.nav-switch {
		display: block;
		width: 40px;
		float: right;
		height: 31px;
		padding-top: 14px;
		position: relative;
		cursor: pointer;
		z-index: 99;
	}
	.nav-switch .ns-bar {
		width: 30px;
		height: 3px;
		background: #fff;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		transition: all 0.3s;
	}
	.nav-switch .ns-bar:after,
	.nav-switch .ns-bar:before {
		position: absolute;
		content: "";
		width: 100%;
		height: 3px;
		background: #fff;
		left: 0;
		bottom: 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		transition: all 0.3s;
	}
	.nav-switch .ns-bar:before {
		bottom: auto;
		top: 0;
	}
	.nav-switch:hover .ns-bar {
		width: 100%;
	}
	.nav-switch.active .ns-bar {
		width: 0px;
		box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
	}
	.nav-switch.active .ns-bar:after {
		transform: rotate(-43deg);
		transform-origin: left bottom;
	}
	.nav-switch.active .ns-bar:before {
		transform: rotate(43deg);
		transform-origin: left top;
	}
	.main-menu {
		margin-left: 0;
		padding-top: 0;
		display: block;
		margin-bottom: 80px;
	}
	.main-menu li {
		display: block;
		border-bottom: 1px solid #f1f1f1;
	}
	.main-menu li a {
		display: block;
		color: #000;
		margin-left: 0;
		padding: 12px 21px;
	}
	.main-menu li.active a {
		color: #fff;
	}
	.header-btns {
		position: relative;
		display: block;
		padding: 0 15px;
	}
	.header-btns .site-btn {
		margin-right: 0;
		display: block;
		margin-bottom: 5px;
	}
	.hs-item {
		/*height: auto;*/
		padding-top: 250px;
		padding: 250px 0 200px;
	}
	.service-list.service-list-left {
		margin-bottom: 40px;
	}
	.service-list li {
		margin-bottom: 40px;
		max-width: auto;
	}
	.service-list.service-list-left .sl-icon {
		float: right;
		padding-left: 0;
	}
	.service-list.service-list-left .sl-content {
		text-align: right;
		padding-right: 95px;
		padding-left: 0;
	}
	.service-list.service-list-left li:nth-child(1) {
		right: -0;
		width: auto;
	}
	.service-list.service-list-left li:nth-child(2) {
		right: 0;
	}
	.service-list.service-list-left li:nth-child(3) {
		right: 0;
	}
	.service-list.service-list-right li:nth-child(1) {
		left: 0;
		width: auto;
	}
	.service-list.service-list-right li:nth-child(2) {
		left: 0;
	}
	.service-list.service-list-right li:nth-child(3) {
		left: 0;
	}
	.service-list.service-list-left .sl-icon {
		float: left;
	}
	.service-list.service-list-left .sl-content {
		text-align: left;
		padding-right: 0;
		padding-left: 0;
		padding-left: 95px;
	}
	.service-main-circle {
		display: none;
	}
	.about-img {
		text-align: center;
		padding-top: 80px;
	}
	.milestone-icon {
		margin-bottom: 30px;
	}
	.banner-card {
		padding: 53px 50px 28px;
	}
	.hs-item h2,
	.page-top-section h2 {
		font-size: 55px;
	}
	.footer-nav,
	.copyright {
		float: none;
		text-align: center;
	}
	.copyright {
		padding-top: 15px;
	}
	.testimonials-slider.owl-carousel .owl-nav {
		position: relative;
		top: 0;
		text-align: center;
		padding-top: 50px;
	}
	.testimonials-slider.owl-carousel .owl-nav button.owl-prev,
	.testimonials-slider.owl-carousel .owl-nav button.owl-next {
		float: none;
		left: 0;
		right: 0;
		width: 50px;
		height: 50px;
		font-size: 20px;
		margin: 0 9px;
	}
	.testimonials-slider.owl-carousel .owl-dots {
		padding-top: 30px;
	}
	.add-pic,
	.contact-text,
	.milestone-icon,
	.about-intro-pic,
	.feature,
	.what-card {
		margin-bottom: 40px;
	}
}

/* Small Mobile :320px. */

@media only screen and (max-width: 479px) {
	.hs-item h2 {
		font-size: 45px;
	}
	.section-title h2,
	.page-top-section h2 {
		font-size: 36px;
	}
	.banner-card {
		padding: 53px 40px 28px;
	}
	.banner-card h2 {
		font-size: 36px;
	}
	.about-list {
		padding-left: 40px;
	}
}
