
// Define only SASS variables in this file.

$theme-colour: #3b8436;


body {
    font-family: 'Quicksand', sans-serif;
  }
  
  .gal-container {
    padding: 12px;
  }
  
  .gal-item {
    overflow: hidden;
    padding: 3px;
  }
  
  .gal-item .box {
    height: 300px;
    overflow: hidden;
  }
  
  .box img {
    height: 110%;
    width: 110%;
    top: -10%;
    object-fit: cover;
    -o-object-fit: cover;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .box:hover img{
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .gal-item a:focus {
    outline: none;
  }
  
  .gal-item a{
    cursor: pointer;
  }
  
  .gal-item a::after {
    content: "\f00e";
    font-family: "FontAwesome";
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    right: 3px;
    left: 3px;
    top: 3px;
    bottom: 3px;
    text-align: center;
    line-height: 300px;
    font-size: 30px;
    color: #fff;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .gal-item a:hover:after {
    opacity: 1;
  }
  
  .modal-open .gal-container .modal {
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-open .gal-item .modal-body {
    padding: 0px;
  }
  
  .modal-open .gal-item button.close {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #000;
    opacity: 1;
    color: #fff;
    z-index: 999;
    right: -12px;
    top: -12px;
    border-radius: 50%;
    font-size: 15px;
    border: 2px solid #fff;
    line-height: 25px;
    -webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
  }
  
  .modal-open .gal-item button.close:focus {
    outline: none;
  }
  
  .modal-open .gal-item button.close span {
    position: relative;
    top: -3px;
    font-weight: lighter;
    text-shadow: none;
  }
  
  .gal-container {
    width: 80%;
  }
  
  
  @media (min-width: 768px) {
    .gal-container .modal-dialog {
      width: 55%;
      margin: 50 auto;
    }
  }
  
  @media (max-width: 768px) {
    .gal-container .modal-content {
      height: 250px;
    }
  }
  
  
  /* Modal */
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg:hover {opacity: 0.7;}
  
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.9); 
  }
  
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  .modal-content, #caption { 
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }
.fab {
  padding: 15px 0px 10px 10px;
}
  
  
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }

  .rounded-review {
    border-radius: 20PX;
}
.review-white{
  background-color: white;
  width: 100%;
  height: 100%;
}
.bg-review-white{
  background-image: url("../img/reviews/colours/review-white.jpg");
  background-size: cover;
  margin: 10px;
  color: black;
}
.bg-review-red {
  background-image: url("../img/reviews/colours/review-red.jpg");
  background-size: cover;
  margin: 10px;
}
.bg-review-blue {
  background-image: url("../img/reviews/colours/review-blue.jpg");
  background-size: cover;
  margin: 10px;
}
.bg-review-green {
  background-image: url("../img/reviews/colours/review-green.jpg");
  background-size: cover;
  margin: 10px;
}
.bg-review-yellow {
  background-image: url("../img/reviews/colours/review-yellow.jpg");
  background-size: cover;
  margin: 10px;
}
.bg-review-pink {
  background-image: url("../img/reviews/colours/review-pink.jpg");
  background-size: cover;
  margin: 10px;
}
.review-logo {
  width: 12%;
  height: 100%;
}
.text-theme {
  color: $theme-colour;
}
/* Customize the embedded stream container */
iframe {
  border: none; /* Remove iframe border */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
  border-radius: 10px; /* Rounded corners for a modern look */
  overflow: hidden; /* Hide horizontal overflow */
  background-color: #fff; /* Set background color to white */
  margin: 0 auto; /* Center the iframe horizontally */
  width: 100%;
  height: 900px;
}

/* Style the iframe title (optional) */
iframe[title="ymmlivestream"] {
  font-size: 18px; /* Adjust the title font size */
  font-weight: bold; /* Make the title bold */
  padding: 10px; /* Add some padding around the title */

}

/* Style the iframe content (optional) */
iframe[title="ymmlivestream"] body {
  margin: 0; /* Reset margin inside the iframe */
  padding: 10px; /* Add padding inside the iframe content */
  
}
.ymmlivestream {
  padding: 20px 20px 20px 20px;
  width: 100%;
}
.live {
  width: 100%;
}
